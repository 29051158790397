<template>
  <div class="TeamManagement">
    <div
      style="
        background: #fff;
        height: 49px;
        box-sizing: border-box;
        padding: 0 12px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <div
        class="input-date"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        "
      >
        <div style="font-size: 12px; color: #2f2f2f">筛选</div>
        <el-date-picker
          v-model="startDate"
          value-format="yyyy-MM-dd"
          type="datetime"
          placeholder="选择开始日期"
          style="width: 105px"
        ></el-date-picker>
        <el-date-picker
          v-model="endDate"
          value-format="yyyy-MM-dd"
          type="datetime"
          placeholder="选择结束日期"
          style="margin-left: 7px; width: 105px"
        ></el-date-picker>
        <div
          style="
            width: 64px;
            font-size: 14px;
            height: 33px;
            line-height: 33px;
            text-align: center;
            border-radius: 2px;
            background: #4372fc;
            color: #fff;
          "
        >
          搜索
        </div>
      </div>
    </div>

    <div
      style="
        height: 57px;
        padding: 0 17px;
        color: #3c3c3c;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      "
    >
      <div style="display: flex; align-items: center">
        <div>全部战队</div>
        <div style="color: #f5f5f5">({{ teamList.length }})</div>
      </div>
      <div
        @click="handleAddTeam"
        style="
          font-weight: 400;
          color: #fff;
          background: #4372fc;
          font-size: 12px;
          width: 107px;
          height: 35px;
          line-height: 35px;
          text-align: center;
          border-radius: 6px;
        "
      >
        新增战队
      </div>
    </div>

    <div style="height: 520px; overflow-y: auto; font-weight: 400">
      <div
        v-for="(item, index) in teamList"
        :key="index"
        style="
          box-sizing: border-box;
          padding: 18px 18px 12px 28px;
          margin-bottom: 12px;
          background: #fff;
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="color: #2f2e2e; font-size: 14px">{{ item.name }}</div>
          <div
            @click="handleClickDeleteTeam(item)"
            style="
              color: #f08411;
              font-size: 12px;
              width: 88px;
              text-align: center;
            "
          >
            删除战队
          </div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            font-size: 12px;
            margin-top: 12px;
          "
        >
          <div style="color: #b0b0b0">战队人数：</div>
          <div style="color: #27dda3">{{ item.total_member }}</div>
        </div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div style="display: flex; align-items: center; font-size: 12px">
            <div style="display: flex; align-items: center">
              <div style="color: #b0b0b0">分享次数</div>
              <div style="color: #4372fc">{{ item.total_share }}</div>
            </div>
            <div style="display: flex; align-items: center">
              <div style="color: #b0b0b0">成交单数：</div>
              <div style="color: #4372fc">{{ item.total_order }}</div>
            </div>
          </div>
          <div
            @click="goTeamDetails(item)"
            style="
              font-size: 12px;
              color: #4372fc;
              box-sizing: border-box;
              border: 1px solid #4372fc;
              border-radius: 5px;
              width: 88px;
              height: 35px;
              line-height: 35px;
              text-align: center;
            "
          >
            查看详情
          </div>
        </div>
      </div>
    </div>

    <!-- 删除战队提示 -->
    <div v-if="isShowDeleteTeamHint">
      <div class="mask"></div>
      <div
        style="
          z-index: 3000;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 323px;
          height: 200px;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 18px 29px;
          background: #fff;
        "
      >
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            margin-top: 45px;
          "
        >
          <div style="color: #393939">确定是否删除战队</div>
          <div style="color: #4372fc">
            "{{ currentOperationTeamInfo.name }}"
          </div>
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            left: 0;
            bottom: 0;
            box-sizing: border-box;
            width: 100%;
            padding: 18px 29px;
          "
        >
          <div
            @click="isShowDeleteTeamHint = false"
            style="
              width: 116px;
              height: 44px;
              border-radius: 5px;
              box-sizing: border-box;
              border: 1px solid #e4e4e4;
              color: #3e3e3e;
              font-size: 16px;
              text-align: center;
              line-height: 42px;
            "
          >
            取 消
          </div>
          <div
            @click="handleDeleteTeam"
            style="
              width: 116px;
              height: 44px;
              border-radius: 5px;
              box-sizing: border-box;
              border: 1px solid #e31a1a;
              color: #e31a1a;
              font-size: 16px;
              text-align: center;
              line-height: 42px;
            "
          >
            删 除
          </div>
        </div>
      </div>
    </div>

    <!-- 新增战队提示 -->
    <div v-if="isShowAddTeamHint">
      <div class="mask"></div>
      <div
        style="
          z-index: 3000;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 323px;
          height: 200px;
          border-radius: 10px;
          box-sizing: border-box;
          padding: 18px 29px;
          background: #fff;
        "
      >
        <div style="font-size: 16px; color: #393939; text-align: center">
          新增战队
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            margin-top: 40px;
            font-weight: 400;
          "
        >
          <div style="color: #393939; flex-shrink: 0">战队名称：</div>
          <el-input
            v-model="addTeamName"
            placeholder="请输入需要新增的战队名称"
          ></el-input>
        </div>

        <div
          style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            left: 0;
            bottom: 0;
            box-sizing: border-box;
            width: 100%;
            padding: 18px 29px;
          "
        >
          <div
            @click="isShowAddTeamHint = false"
            style="
              width: 116px;
              height: 44px;
              color: #cdcdcd;
              font-size: 16px;
              text-align: center;
              line-height: 44px;
            "
          >
            取 消
          </div>
          <div
            @click="handleConfirmAddTeam()"
            style="
              width: 116px;
              height: 44px;
              color: #242f28;
              font-size: 16px;
              text-align: center;
              line-height: 44px;
            "
          >
            确认
          </div>
        </div>
      </div>
    </div>

    <!-- 提示 -->
    <div v-if="isShowHint">
      <div
        style="
          z-index: 3001;
          color: #fff;
          font-weight: 400;
          font-size: 12px;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background: rgba(0, 0, 0, 0.6);
          border-radius: 4px;
          padding: 10px 15px;
        "
      >
        {{ hintContent }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeamManagement",
  components: {

  },
  data() {
    return {
      // 是否显示提示
      isShowHint: false,
      // 提示内容
      hintContent: '',
      // 开始时间
      startDate: '',
      // 结束时间
      endDate: '',
      // 是否显示删除战队提示
      isShowDeleteTeamHint: false,
      // 是否显示新增战队提示
      isShowAddTeamHint: false,
      // 新增战队的战队名称
      addTeamName: '',
      // 活动id
      id: "",
      // 战队列表
      teamList: [],
      // 当前操作的战队成员信息
      currentOperationTeamInfo: {},
    }
  },
  mounted() {
    this.id = parseInt(this.$route.query.id);

    this.getTeamList(this.id);
  },
  methods: {


    /** 处理点击删除战队操作 */
    handleClickDeleteTeam(item) {
      this.currentOperationTeamInfo = item;
      this.isShowDeleteTeamHint = true;
    },

    /** 处理确认删除战队 */
    handleDeleteTeam() {
      this.$axios.post("/teams/delTeam", {
        id: this.currentOperationTeamInfo.id,
        event_id: this.id,
      }).then(res => {
        this.showMessage(res === undefined ? "删除成功" : res);
        this.isShowDeleteTeamHint = false;
        this.getTeamList(this.id);
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 处理新增战队 */
    handleAddTeam() {
      this.addTeamName = "";
      this.isShowAddTeamHint = true;
    },

    /** 处理确认新增战队 或者 编辑 */
    handleConfirmAddTeam(id = 0) {
      this.$axios.post("/teams/saveTeam", {
        name: this.addTeamName,
        event_id: this.id,
        id,
      }).then(res => {
        this.showMessage(res === undefined ? "创建成功" : res);
        this.isShowAddTeamHint = false;
        this.getTeamList(this.id);
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 获取战队列表 */
    getTeamList(id) {
      this.$axios.post("/teams/getAlls", {
        event_id: id,
        begin_day: this.startDate,
        end_day: this.endDate,
      }).then(res => {
        this.teamList = res.data;
      }).catch(error => {
        this.showMessage(error);
      });
    },

    /** 去战队详情 */
    goTeamDetails(item) {
      this.$router.push({ path: '/teamDetails?id=' + this.id + "&teamId=" + item.id + "&title=" + item.name });
    },

    /** 显示提示 */
    showMessage(message) {
      this.hintContent = message;
      this.isShowHint = true;
      const timeout = setTimeout(() => {
        this.isShowHint = false;
        clearTimeout(timeout);
      }, 1000);
    },
  }
};
</script>

<style scoped lang="scss">
.TeamManagement {
  background: #f5f5f5;
  min-height: 100vh;
  height: 100%;
  font-weight: bold;
}

.input-style-none {
  ::v-deep input {
    background: #f8f8f8;
    border: none;
  }
}

.input-date {
  ::v-deep {
    input {
      padding: 0 5px;
      font-size: 12px;
      text-align: center;
      height: 33px;
      line-height: 33px;
    }
    .el-input__prefix {
      display: none;
    }
  }
}

.el-input {
  ::v-deep input {
    border: none !important;
    padding: 0;
  }
}
</style>
